import React from 'react';
import { Link } from 'react-router-dom';
import './NoteList.css';
import NotefulContext from './NotefulContext';
import config from './config';

class FilteredNoteList extends React.Component {
  static contextType = NotefulContext;

  deleteNoteRequest = (noteId, cb) => {
    fetch(config.notes_endpoint + `/${noteId}`, {
      method: 'DELETE'
    })
      .then(res => {
        if (!res.ok) {
          return res.json().then(error => {
            throw error
          })
        }
        return;
      })
      .then(data => {
        cb(noteId)
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    return (
        <>
        <button 
          className="clear-filter"
          onClick={() => this.props.history.push('/')}>
          Clear Filter
          </button>
        <ul>
          {this.context.notes.filter(note => 
          note.folder === parseInt(this.props.match.params.folderId))
          .map(note =>
            <li key={note.id} className='note'>
              <Link to={`/note/${note.id}`}>
                <h2>
                  {note.note_name}
                </h2>
              </Link>
              <p className='date-modified'> Date Modified: {note.modified}</p>
              <button 
              className="delete"
              onClick={() => {
                this.deleteNoteRequest(
                  note.id,
                  this.context.deleteNote
                )
              }}>
              Delete Note
            </button>
            </li>
          )}
        </ul>
        </>
      )
  }
}

export default FilteredNoteList